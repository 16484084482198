import Util from "@/utils/utils";
import { getList, getExport, isFlowStart } from "./api";
import hzTranslation from '../components/hzTranslation'
import { terminalActivityByIdStepBuildStore } from "@/views/basicInformation/components/distributorManagement/api";
import { env } from "@/common/constant";
export default {
  name: "",
  components:{
    hzTranslation
  },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/jurisdiction/merchants",
          name: "管辖的商家",
          title: "管辖的商家"
        }
      ],
      visibleshop: false,
      hideOrShowSize: true,
      columnsAll:[],
      columns: [
        {
          label: "商家名称",
          prop: "fullName",
          width: 200,
        },
        {
          label: "商家编码",
          prop: "cisCode",
          width: 200,
        },
        {
          label: "创建时间",
          prop: "createdDateStr",
          width: 200,
        },
        {
          label: "法人",
          prop: "legalPerson",
          width: 200,
        },
        {
          label: "老板手机号",
          prop: "bossTel",
          width: 200,
        },
        {
          label: "行政区划",
          prop: "administrativeDivision",
          width: 200,
        },
        {
          label: "网格区域",
          prop: "gridName",
          width: 200,
        },
        {
          label: "办事处",
          prop: "office",
          width: 200,
        },
        {
          label: "营销中心",
          prop: "marketCenter",
          width: 200,
        },
        {
          label: "审批状态",
          prop: "approvalStatus",
          width: 200,
        },
        {
          label: "驳回原因",
          prop: "approvalOpinion",
          width: 200,
        },

        {
          label: "数据来源",
          prop: "operatePlatform",
          width: 200,
        },
        {
          label: "操作",
          prop: "",
          key: "btnGroup",
          slot: "btnGroup",
          width: 280,
          fixed: "right",
        }

      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        "custId":this.$store.state.user.userInfo.customer.id,
        "gridManager":this.$store.state.user.userInfo.account.account,
      },
      searchNumber: 0,
      isLoading: false,
      tableLoading: false,
      visibleHZ: false,
      selectId: '',
      selectName: '',
      delectVisible:false,
      delectId:''
    };
  },
  created() {
    this.columnsAll = this.columns;
  },
  mounted() {
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    // 作废
    delect(){
      terminalActivityByIdStepBuildStore({ id: this.delectId}).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.tablelist()
        }else{
          this.$message.warning(res.data.msg);
          this.tablelist()
        }
      });
    },
    // 作废
    goTerminalActivityById(feed) {
      this.delectId = feed.id
      this.delectVisible=true
    },
    // 查看流程
    goFlow(row) {
      // window.open(
      //   `/auth-api/displayFlow?param=${feed.processInstId}#/`
      // );
      let id =  row.processInstId
      if (env == "dev" || env == "test" || env == "uat") {
        // 测试
        window.open('http://xtwdev.clouddev.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      } else {
        // 正式
        window.open('https://xtw.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      }
    },
    openHz(selRows){
      this.selectId = selRows.id;
      this.selectName = selRows.fullName
      this.visibleHZ = true
    },
    addnewMerchant(type,item) {
      if (type == "add") {
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
          query:{
            url:'jurisdictionalMerchants'
          }
        });
        window.open(routeUrl.href, "_blank");
      }
      if (type == "edit") {
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
          query: {
            id: item.id,
            type: "edit",
            url:'jurisdictionalMerchants'
          },
        })
        if(item.approvalStatus=='已完成'){
          isFlowStart(item.id).then(res=>{
            // 如果接口返回的code 弹出错误提示
            if(res.data.code !==0){
              this.$message.warning(res.data.msg)
              return
            }
            // 如果返回了创建人 说明有在流程中的数据 弹出提示 否则就直接可以编辑
            if(res.data.code == 0 && res.data.data && res.data.data.creator){
              this.$message.warning(`该商家目前正在被${res.data.data.creator}发起流程，流程还未审批通过，暂时不能发起流程，紧急情况请直接联系发起人督办尽快完成审批。`)
            }else{
              window.open(routeUrl.href, "_blank");
            }
          })
          return
        } else{
          window.open(routeUrl.href, "_blank");
        }
      }
      if (type == "view") {
        let routeUrl = this.$router.resolve({
          path: "/newMerchantaddEdit",
          query: {
            id: item.id,
            name: "view",
            url:'jurisdictionalMerchants'
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 点击搜索进行数据查询(配置的筛选条件)
    searchFun(arg) {
      this.ssearchList = {
        ...this.pager.pageNo,
        ...this.pager.pageSize,
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    exportClick() {
      let data = this.ssearchList;
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "代理商市场活动列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    pageSearch(pager) {
      this.ssearchList.pageNo = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.pager.pageNo = pager.pageNo
      this.pager.pageSize = pager.pageSize
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.list;
          this.searchNumber = res.data.totalRows;
          this.pager.count = res.data.totalRows;
          this.tableLoading = false
          if (this.tableData.length>0){
            this.tableData.forEach(item=>{
              item.administrativeDivision = ''
              // 行政区划 拼接
              item.administrativeDivision = item.provinceName+item.cityName+item.countyName+item.townName

            })

          }
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }

      });
    },

    // 重新初始化表头
    initTableColumns(tableColumns) {
      this.columns = tableColumns.filter(j=>j.isShow === '1');
    },

  }
};
