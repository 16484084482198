import http from "@/utils/request";

// 获取列表
export function getList(data) {
  return http({
    method: "get",
    url: "/fast/cust/customerInfoGrid/page.nd",
    params:data
  });
}

//   获取营销中心的列表
export function getYxList(data) {
  return http({
    method: "post",
    url: "/custAgentActivity/getMarketCenter.nd",
    data
  });
}

// 审批状态

export function getStatusList(data) {
  return http({
    method: "post",
    url: "/custAgentActivity/getCheckStatus.nd",
    data
  });
}
// export function getExport(data) {
//     return http({
//         url: 'cts/ctsApi.nd',
//         method: 'get',
//         params:data,
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//         responseType: 'blob'
//     })
// }



// 导出

export function getExport(data) {
  return http({
    url: '/fast/cust/customerInfoGrid/export.nd',
    method: 'get',
    params: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob'
  })
}

//  删除
export function delLine(data) {
  return http({
    method: "post",
    url: "/custAgentActivity/deleteActivityById.nd",
    data
  });
}

// 审核
export function shLc(data) {
  return http({
    method: "post",
    url: "/custAgentActivity/agree.nd",
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  });
}
// 获取管辖的商家合作关系
export function getCustomerPartnership(data) {
  return http({
    method: "get",
    url: "/grid/cust/getCustomerPartnership/page.nd",
    params:data
  });
}
//查询是否有审批中的数据
export function isFlowStart(data) {
  return http({
    method: "get",
    url: "/grid/cust/isFlowStart/"+data+'.nd',
  });
}